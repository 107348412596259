import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://0b6a3b3582026ab2055e1bad048540a8@o4507472358604800.ingest.de.sentry.io/4507472382197840",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});